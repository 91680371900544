<template>
  <div style="height:100%;padding-top:60px;">
    <div ref="wrap" class="wrap" @scroll="scroll">
      <div ref="banner" class="banner"
        :style="{backgroundImage: 'url(' + staticUrl+'design/home%20page/banner.png' + ')'}">
        <div class="container flex flex-v flex-align-center flex-pack-center" style="height:100%;">
          <div ref="item" class="item flex flex-v flex-align-center">
            <!-- <label>邀请内测版</label> -->
            <label></label>
            <h2>智联万家</h2>
            <div>中国家居产业智联网</div>
            <span @click="join()">加入我们</span>
          </div>
          <div class="wave footer-wave"></div>
          <div class="arrow" @click="scrollBtm()">
            <span></span>
          </div>
        </div>
        <div class="zixun">
          产品咨询
          <div class="qrcode-wrap flex flex-v flex-align-center"> 
            <span>1对1指导</span>
            <div>扫码咨询</div>
            <img src="../assets/images/home/qrcode-zixun.jpg" />
          </div>
        </div>
      </div>
      <div class="block-1">
        <div class="container">
          <h3>服务场景</h3>
          <p>家具生产制造全产业协作平台</p>
          <div class="feature flex flex-pack-center">
            <span class="label flex flex-align-center">
              <i></i>全产业入驻
            </span>
            <span class="label flex flex-align-center">
              <i></i>产业B端协作
            </span>
            <span class="label flex flex-align-center">
              <i></i>丰富多维度产业信息
            </span>
          </div>
          <div ref="animateItem4" class="list flex" :class="{ animated: animation.flag4 }">
            <div class="list-item">
              <div class="circle circle-1"></div>
              <div class="circle circle-2"></div>
              <div class="circle circle-3"></div>
              <div class="img-wrap flex flex-align-center flex-pack-center">
                <div class="img-1"></div>
              </div>
              <div>家具采购一站式，渠道服务多维度.</div>
              <h4>家具采购</h4>
            </div>
            <div class="list-item">
              <div class="circle circle-1"></div>
              <div class="circle circle-2"></div>
              <div class="circle circle-3"></div>
              <div class="img-wrap flex flex-align-center flex-pack-center">
                <div class="img-2"></div>
              </div>
              <div>专业设计千百款，周周爆款任你挑</div>
              <h4>家具设计</h4>
            </div>
            <div class="list-item">
              <div class="circle circle-1"></div>
              <div class="circle circle-2"></div>
              <div class="circle circle-3"></div>
              <div class="img-wrap flex flex-align-center flex-pack-center">
                <div class="img-3"></div>
              </div>
              <div>销售订单快准多，生产进度时跟踪</div>
              <h4>家具生产</h4>
            </div>
            <div class="list-item">
              <div class="circle circle-1"></div>
              <div class="circle circle-2"></div>
              <div class="circle circle-3"></div>
              <div class="img-wrap flex flex-align-center flex-pack-center">
                <div class="img-4"></div>
              </div>
              <div>产业信息全透明，企业协作更便捷</div>
              <h4>家具供应链</h4>
            </div>
          </div>
        </div>
      </div>
      <div class="block-2">
        <div class="container">
          <div class="flex flex-justify-between flex-align-center">
            <div class="info">
              <h4>家具生产工厂</h4>
              <span class="label flex flex-align-center">
                <i></i>设计版权购买
              </span>
              <span class="label flex flex-align-center">
                <i></i>与设计师分成合作
              </span>
              <span class="label flex flex-align-center">
                <i></i>生产备料、白胚等协作服务
              </span>
              <span class="label flex flex-align-center">
                <i></i>订单、物流协同
              </span>
            </div>
            <div
              ref="animateItem1"
              class="img-wrap translate-right"
              :class="{ animated: animation.flag1 }"
            >
              <img class="img-1" :src="staticUrl+'design/home%20page/img-1.png'" />
            </div>
          </div>
          <div class="flex flex-justify-between flex-align-center">
            <div
              ref="animateItem2"
              class="img-wrap translate-left"
              :class="{ animated: animation.flag2 }"
            >
              <img class="img-1" :src="staticUrl+'design/home%20page/img-2.png'" />
            </div>
            <div class="info">
              <h4>家具采购公司</h4>
              <span class="label flex flex-align-center">
                <i></i>全产业带工厂家具产品搜索
              </span>
              <span class="label flex flex-align-center">
                <i></i>新品打样服务
              </span>
              <span class="label flex flex-align-center">
                <i></i>工程单加工，订单加工，外贸单加工等
              </span>
            </div>
          </div>
          <div class="flex flex-justify-between flex-align-center">
            <div class="info">
              <h4>设计公司入驻</h4>
              <span class="label flex flex-align-center">
                <i></i>版权出售
              </span>
              <span class="label flex flex-align-center">
                <i></i>设计制造合作分成
              </span>
              <span class="label flex flex-align-center">
                <i></i>渲染、 拍照等设计服务
              </span>
            </div>
            <div
              ref="animateItem3"
              class="img-wrap translate-right"
              :class="{ animated: animation.flag3 }"
            >
              <img class="img-1" :src="staticUrl+'design/home%20page/img-3.png'" />
            </div>
          </div>
        </div>
      </div>
      <div class="block-3"
        :style="{backgroundImage: 'url(' + staticUrl+'design/home%20page/btm-bg.png' + ')'}">
        <div class="wave top-wave"></div>
        <div class="container">
          <p>让产品更贴近用户 让供应链管理更容易 让生产更简单</p>
          <div class="list flex flex-pack-center">
            <div class="flex flex-align-center">
              <i class="icon-1"></i>免费试用
            </div>
            <div class="flex flex-align-center">
              <i class="icon-2"></i>1V1服务
            </div>
            <div class="flex flex-align-center">
              <i class="icon-3"></i>在线客服
            </div>
            <div class="flex flex-v flex-align-center flex-pack-center">
              <img src="../assets/images/home/qr.jpg" />
              <span>微信公众号</span>
            </div>
          </div>
          <div class="copyright">
            <span>版权所有</span>
            <div>Copyright@2020 All Rights Reserved</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { STATIC_URL_PRE } from '@/config'; // 静态文件地址（"https://cdn.file.nk.emergen.cn/"）
import { throttle } from "lodash"; // 插件，安装一下即可
let lFollowX = 0,
  lFollowY = 0,
  x = 0,
  y = 0,
  friction = 1 / 10;
let flag = false;
export default {
  data() {
    return {
      staticUrl:STATIC_URL_PRE, // 静态文件地址（"https://cdn.file.nk.emergen.cn/"）
      animation: {
        flag1: false,
        flag2: false,
        flag3: false,
        flag4: false
      },
      timer: null
    };
  },
  created() {
    this.scroll = throttle(this.scroll, 100); 
  },
  mounted() {
    this.move = this.move.bind(this);
    document.addEventListener("mousemove", this.move);
    this.animate();
  },
  beforeDestroy() {
    document.removeEventListener("mousemove", this.move);
    cancelAnimationFrame(this.raf);
  },
  methods: {
    scroll(e) {
      const scrollTop = e.target.scrollTop;
      const {
        banner,
        animateItem1,
        animateItem2,
        animateItem3,
        animateItem4
      } = this.$refs;
      if (scrollTop + banner.offsetHeight >= animateItem1.offsetTop) {
        if (!this.animation.flag1) {
          this.animation.flag1 = true;
        }
      } else {
        if (this.animation.flag1) {
          this.animation.flag1 = false;
        }
      }
      if (scrollTop + banner.offsetHeight >= animateItem2.offsetTop) {
        if (!this.animation.flag2) {
          this.animation.flag2 = true;
        }
      } else {
        if (this.animation.flag2) {
          this.animation.flag2 = false;
        }
      }
      if (scrollTop + banner.offsetHeight >= animateItem3.offsetTop) {
        if (!this.animation.flag3) {
          this.animation.flag3 = true;
        }
      } else {
        if (this.animation.flag3) {
          this.animation.flag3 = false;
        }
      }
      if (scrollTop + banner.offsetHeight >= animateItem4.offsetTop) {
        if (!this.animation.flag4) {
          this.animation.flag4 = true;
        }
      } else {
        if (this.animation.flag4) {
          this.animation.flag4 = false;
        }
      }
    },
    join() {
      this.$router.push("/login");
    },
    scrollBtm() {
      const t = 200;
      const wrap = this.$refs.wrap;
      const bannerHeight = this.$refs.banner.offsetHeight;
      const finalScrollTop = bannerHeight;
      const dH = finalScrollTop / (t / 10);
      this.timer = setInterval(() => {
        if (wrap.scrollTop + dH > finalScrollTop) {
          clearInterval(this.timer);
          wrap.scrollTop = finalScrollTop;
          return;
        }
        wrap.scrollTop += dH;
      }, 10);
    },
    animate() {
      x += (lFollowX - x) * friction;
      y += (lFollowY - y) * friction;

      this.$refs.item.style.transform =
        "perspective(600px) rotateY(" + -x + "deg) rotateX(" + y + "deg)";
      this.raf = window.requestAnimationFrame(this.animate);
    },
    move(e) {
      let lMouseX = Math.max(
        -100,
        Math.min(100, this.$refs.banner.offsetWidth / 2 - e.clientX)
      );
      let lMouseY = Math.max(
        -100,
        Math.min(100, this.$refs.banner.offsetHeight / 2 - e.clientY)
      );
      lFollowX = (12 * lMouseX) / 100; // 100 : 12 = lMouxeX : lFollow
      lFollowY = (10 * lMouseY) / 100;
    }
  }
};
</script>

<style scoped lang="less">
@keyframes rotate {
  0% {
    transform: rotateY(0) rotateZ(-45deg) translate(0, 0);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
  100% {
    transform: rotateY(720deg) rotateZ(-45deg) translate(-40px, 40px);
    opacity: 0;
  }
}
@keyframes wave {
  0% {
    background-position: 0;
  }
  100% {
    background-position: 1440px;
  }
}
.wrap {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
.container {
  width: 1200px;
  margin: 0 auto;
  flex-wrap: nowrap;
}
.wave {
  max-width: 102%;
  width: 100%;
  position: absolute;
  background: url("../assets/images/home/new/hero-wave.svg") repeat-x;
  animation: wave 10s cubic-bezier(0.44, 0.66, 0.67, 0.37) infinite;
  height: 188px;
  z-index: 1;
}
.banner {
  height: 100%;
  // background: url(../assets/images/home/new/banner.png) center center no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  .zixun {
    width: 88px;
    height: 36px;
    text-align: center;
    line-height: 34px;
    background: rgba(18, 150, 219, 1);
    border: 2px solid rgba(255, 255, 255, 1);
    position: fixed;
    top: 80%;
    right: 110px;
    color: #fff;
    border-radius: 18px;
    cursor: pointer;
    z-index: 999;
    &:hover {
      .qrcode-wrap {
        display: flex;
      }
    }
    .qrcode-wrap {
      width: 154px;
      height: 204px;
      position: absolute;
      left: 50%;
      margin-left: -77px;
      top: -225px;
      background: #fff;
      border: 4px solid rgba(18, 150, 219, 1);
      font-size: 18px;
      display: none;
      &:before {
        display: block;
        content: "";
        border-top: 10px solid #fff;
        border-left: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-right: 10px solid transparent;
        position: absolute;
        bottom: -20px;
        left: 50%;
        margin-left: -10px;
        z-index: 2;
      }
      &:after {
        display: block;
        content: "";
        border-top: 16px solid rgba(18, 150, 219, 1);
        border-left: 16px solid transparent;
        border-bottom: 16px solid transparent;
        border-right: 16px solid transparent;
        position: absolute;
        bottom: -32px;
        left: 50%;
        margin-left: -16px;
      }
      span {
        color: #1296db;
      }
      div {
        color: #ccc;
      }
      img {
        width: 120px;
        height: 120px;
      }
    }
  }
  .item {
    border: 5px solid #fff;
    width: 445px;
    height: 628px;
    color: #fff;
    font-size: 32px;
    transform-style: preserve-3d;
    transform: perspective(600px) rotateX(0) rotateY(0);
    label {
      margin-top: 80px;
    }
    h2 {
      font-size: 56px;
      margin: 50px 20px;
      color: #fff;
      font-weight: bold;
    }
    span {
      width: 150px;
      height: 36px;
      background-color: #00ccff;
      border-radius: 4px;
      text-align: center;
      line-height: 36px;
      font-size: 18px;
      margin-top: 100px;
      cursor: pointer;
    }
  }
  .arrow {
    position: relative;
    cursor: pointer;
    z-index: 2;
    width: 80px;
    height: 100px;
    margin-top: 30px;
    span {
      position: absolute;
      top: 0;
      left: 50%;
      width: 50px;
      height: 50px;
      margin-left: -12px;
      border-left: 4px solid #fff;
      border-bottom: 4px solid #fff;
      transform: rotateZ(-45deg);
      animation: rotate 1.5s infinite;
    }
  }
  .footer-wave {
    left: 0;
    bottom: -67px;
  }
}
.label {
  font-size: 24px;
  color: #847d77;
  font-weight: bold;
  i {
    width: 30px;
    height: 30px;
    background: url("../assets/images/home/new/gou.png") no-repeat;
    background-size: cover;
    margin-right: 10px;
  }
}
.block-1 {
  background-color: #fff;
  .container {
    text-align: center;
    h3 {
      color: #080245;
      font-size: 64px;
      font-weight: bold;
      margin-bottom: 30px;
      padding-top: 40px;
    }
    p {
      font-size: 32px;
      color: #5074ee;
      margin-bottom: 20px;
      font-weight: bold;
    }
    .feature {
      span {
        margin-left: 60px;
        &:first-child {
          margin-left: 0;
        }
      }
    }
    .list {
      padding: 64px 0;
      transform: translate3d(0, 100px, 0);
      transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75);
      transition-duration: 1s;
      transition-property: opacity, transform;
      opacity: 0;
      &.animated {
        transform: translateZ(0);
        opacity: 1;
      }
      .list-item {
        width: 287px;
        height: 354px;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
        background-color: #fff;
        border-radius: 6px;
        margin-left: 17px;
        transition: background-color 0.3s;
        position: relative;
        overflow: hidden;
        &:first-child {
          margin-left: 0;
        }
        &:hover {
          background-color: #15cddd;
          div {
            color: #fff;
          }
          h4 {
            color: #fff;
          }
          .circle {
            transform: scale(1);
          }
          .img-wrap {
            .img-1 {
              background-image: url("../assets/images/home/new/icon-1-hover.png");
            }
            .img-2 {
              background-image: url("../assets/images/home/new/icon-2-hover.png");
            }
            .img-3 {
              background-image: url("../assets/images/home/new/icon-3-hover.png");
            }
            .img-4 {
              background-image: url("../assets/images/home/new/icon-4-hover.png");
            }
          }
        }
        .circle {
          border-radius: 50%;
          position: absolute;
          background-color: #00a0e9;
          transform: scale(0);
          transition: transform 0.6s;
          &.circle-1 {
            width: 102px;
            height: 102px;
            left: -51px;
            top: -51px;
            z-index: 2;
          }
          &.circle-2 {
            width: 220px;
            height: 220px;
            left: -110px;
            top: -110px;
            background-color: #65b6e5;
          }
          &.circle-3 {
            width: 102px;
            height: 102px;
            right: -51px;
            bottom: -51px;
          }
        }
        .img-wrap {
          height: 261px;
          position: relative;
          z-index: 3;
          .img-1 {
            width: 164px;
            height: 155px;
            background-image: url("../assets/images/home/new/icon-1.png");
            background-size: 164px 155px;
            background-repeat: no-repeat;
          }
          .img-2 {
            width: 170px;
            height: 153px;
            background-image: url("../assets/images/home/new/icon-2.png");
            background-size: 170px 153px;
            background-repeat: no-repeat;
          }
          .img-3 {
            width: 145px;
            height: 173px;
            background-image: url("../assets/images/home/new/icon-3.png");
            background-size: 145px 173px;
            background-repeat: no-repeat;
          }
          .img-4 {
            width: 184px;
            height: 150px;
            background-image: url("../assets/images/home/new/icon-4.png");
            background-size: 184px 150px;
            background-repeat: no-repeat;
          }
        }

        div {
          color: #666;
        }
        h4 {
          font-size: 24px;
          color: #000;
          margin-top: 18px;
        }
      }
    }
  }
}
.block-2 {
  padding: 174px 0;
  background: rgba(245, 246, 250, 1);
  .container {
    > div {
      padding-top: 118px;
      &:first-child {
        padding-top: 0;
      }
      .info {
        h4 {
          font-size: 32px;
          color: #0a8aff;
          font-weight: bold;
          margin-bottom: 26px;
        }
        span {
          margin-bottom: 22px;
        }
      }
      .img-wrap {
        transition-property: opacity, transform;
        transition-duration: 1s;
        transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75);
        opacity: 0;
        &.translate-right {
          transform: translate3d(100px, 0, 0);
        }
        &.translate-left {
          transform: translate3d(-100px, 0, 0);
        }
        &.animated {
          transform: translateZ(0);
          opacity: 1;
        }
        img {
          &.img-1 {
            width: 558px;
            height: 523px;
          }
          &.img-2 {
            width: 669px;
            height: 499px;
          }
          &.img-3 {
            width: 596px;
            height: 559px;
          }
        }
      }
    }
  }
}
.block-3 {
  // background: url(../assets/images/home/new/btm-bg.png) center center no-repeat;
  background-position: center;
  background-size: cover;
  color: #fff;
  padding-top: 118px;
  position: relative;
  .top-wave {
    top: -67px;
    left: 0;
    transform: rotate(-180deg);
    background: url("../assets/images/home/new/hero-wave-2.svg") repeat-x;
    animation-direction: reverse;
  }
  p {
    font-size: 24px;
    text-align: center;
    margin-bottom: 72px;
    font-weight: bold;
  }
  .list {
    > div {
      font-size: 28px;
      margin-left: 162px;
      font-weight: bold;
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-left: 107px;
      }
      i {
        margin-right: 16px;
        &.icon-1 {
          width: 45px;
          height: 45px;
          background: url("../assets/images/home/new/btm-icon-1.png") no-repeat;
          background-size: 45px 45px;
        }
        &.icon-2 {
          width: 50px;
          height: 50px;
          background: url("../assets/images/home/new/btm-icon-2.png");
          background-size: 50px 50px;
        }
        &.icon-3 {
          width: 52px;
          height: 44px;
          background: url("../assets/images/home/new/btm-icon-3.png");
          background-size: 52px 44px;
        }
      }
      img {
        width: 70px;
        height: 70px;
      }
      > span {
        font-weight: 400;
        font-size: 14px;
        margin-top: 8px;
      }
    }
  }
  .copyright {
    text-align: center;
    border-top: 2px solid #5e98dc;
    padding-top: 23px;
    margin-top: 84px;
    padding-bottom: 24px;
  }
}
</style>
